import type { ProgramWrapper } from "~/utils/program_wrapper";
import { clientOnly } from "@solidjs/start";
import { Show } from "solid-js";

import { utmStoreGet } from "~/lib/utm_store";

import "./ProgramFormInPage.css";

const FormInPage = clientOnly(() => import("../../Forms/InPage"));

export default function ProgramFormInPage(props: { wrapper: ProgramWrapper }) {
  const hiddenFields = () => {
    return {
      base_email: utmStoreGet("utm_source"),
      campaign: utmStoreGet("utm_campaign"),
      source: utmStoreGet("utm_source"),
      medium: utmStoreGet("utm_medium"),
      term: utmStoreGet("utm_term"),
      content: utmStoreGet("utm_content"),
    };
  };

  return (
    <>
      <section class="form-in-page-wrapper">
        <div class="form-in-page form-in-page-white" data-test="form-in-page">
          <Show when={props.wrapper.program.field_form.field_title}>
            <h2>{props.wrapper.program.field_form.field_title}</h2>
          </Show>
          <div data-ga-zone="form">
            <Show when={props.wrapper.program.field_form.field_catchline}>
              <p class="catchline" data-test="catchline">
                {props.wrapper.program.field_form.field_catchline}
              </p>
            </Show>
            <FormInPage
              wrapper={props.wrapper}
              formConfig={props.wrapper.program.field_form}
              defaultValues={{
                program_nid: String(props.wrapper.program.drupal_internal__nid),
              }}
              hiddenFields={{ form_id: "in-page", ...hiddenFields() }}
              formNameForEvent={getFormEventNameFromCrmObject(
                props.wrapper.program.field_form.field_crm_object,
              )}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export function getFormEventNameFromCrmObject(crmObject: string) {
  switch (crmObject) {
    case "Information":
    case "Information programme":
    case "Information multiprogramme":
    default:
      return "information";
    case "Inscription Avant-première programme":
    case "Inscription Avant-première multiprogramme":
      return "preview";
    case "Inscription campagne captation":
    case "Rendez-vous campagne captation":
      return "cnat";
    case "Inscription Evenement":
    case "Inscription Evenement programme":
    case "Inscription Evenement multiprogramme":
      return "event";
  }
}
